export const formatPrice = (price: number) => {
  if (price === undefined || price === null) {
    price = 0;
  }
  const euros = price
    ?.toString()
    .split(".")[0]
    // https://stackoverflow.com/a/16637170
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const cents = price.toFixed(2).toString().split(".")[1];
  return { euros, cents };
};

export const formatPriceWithCurrency = (
  price: number,
  truncateCents = true,
) => {
  const { euros, cents } = formatPrice(price);
  if (cents === "00" && truncateCents) {
    return `${euros} €`;
  }
  return `${euros},${cents} €`;
};

export const formatPriceWithPercent = ({
  isPercentage = true,
  value,
}: {
  isPercentage?: boolean;
  value: number;
}) => {
  const { euros, cents } = formatPrice(isPercentage ? value * 100 : value);
  return `${euros},${cents} %`;
};
